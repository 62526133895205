import axios from 'axios';
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://brickbox.dev.cat2.me' : '';

export default class SetService {
    getSets(input) {
        // console.log(input);
        const params = new URLSearchParams(input);

        params.append('columns[0][data]', input.sortBy);
        params.append('columns[0][name]', input.sortBy);
        params.append('columns[0][orderable]', 'true');
        params.append('order[0][column]', 0);
        params.append('order[0][dir]', input.sortOrder);

        return axios.get('/api/v1/sets?' + params.toString()).then(res => res.data);
    }

    getSearchParams() {
        return axios.get('/api/v1/sets/search_params').then(res => res.data);
    }

    getRecommend() {
        return axios.get('/api/v1/recommend').then(res => res.data);
    }
}
